
//import { CoreBlockchain } from "@thirdweb-dev/chains";
//import { CoreBlockchainTestnet } from "@thirdweb-dev/chains";

/*export const tokenAddress ='0xc1950A9B1715B6Ef749B409dDdA3d6E973E0e2ca'
export const crowdsaleAddress ='0x7a68e823AaEE9AE070EA3D896d1Cc9979507534B'
export const tetherAddress ='0x76a41272B137C4A440b4eF83feAe6972cd79e6c7'
export const currentChainId = 
*/


//bnb smart chain mainet
export const tokenAddress ='0x627C099ec131bB9Fc234FD977D554a7e75022d5f'
export const crowdsaleAddress ='0x5419210e5341037B60fcab84aca15D6DfF5eb99b'
export const tetherAddress ='0x55d398326f99059ff775485246999027b3197955'
export const currentChainId = 56 
export const chain = 'binance'
export const linkSmartChain = 'bscscan.com'
export const usdtDecimal = 18

/*
//bnb smart chain testnet
export const tokenAddress ='0x4e96B885a61e18AFc62894C4f74575C9ADa4246E'
//'0xd0114b4aDFB975d1BBec00842A39632f7EB9E7A7'
export const crowdsaleAddress = '0x6cA0e62AeeF11092E5947B336D94F3DB651742B1'
//'0x1A7258905507df57dAB32F2B2a69A3816c8bb2EC'
// w-0x4c10E231C6cb4a6d9c47597261c07466Bb9183D3
// wo-0x0754fE8A9Bb7AEdD1B33Df3a6Af76a8Bba72CA89
export const tetherAddress ='0x337610d27c682E347C9cD60BD4b3b107C9d34dDd'
export const currentChainId = 97
export const chain = 'binance-testnet'
export const linkSmartChain = 'testnet.bscscan.com'
export const usdtDecimal = 18
*/

/*
//core blockchain mainet
export const tokenAddress ='0x0F883A270310519F1f331f1824E9639d73440d57'
export const crowdsaleAddress ='0x4b4013E9d52a7749Fa0D73DaD16AEED7012cfB55' //0xA528Ef64C0f26AaD70eAB7B5730d56420C5d9f13'
export const tetherAddress ='0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1'
export const currentChainId = 1116
export const chain = CoreBlockchain
export const linkSmartChain = 'scan.coredao.org'
*/

/*
//core blockchain testnet
export const tokenAddress ='0x0F883A270310519F1f331f1824E9639d73440d57'
export const crowdsaleAddress ='0x76a41272B137C4A440b4eF83feAe6972cd79e6c7'//
export const tetherAddress = '0x3786495F5d8a83B7bacD78E2A0c61ca20722Cce3'
export const currentChainId = 1115
export const chain = CoreBlockchainTestnet
export const linkSmartChain = 'scan.coredao.org'
*/


/////kajy/////
/*

jeton amidy= 60% ny total supply = 12.6 milliard
Fond leve = prix definit * nmbr jeton sale(ilay 12.6milliard izan)

 

 */